<template>
  <div class="flex xs6 xl6">
    <vac-card
      class="chart-widget"
      :title="$t('Insights Per retailers')">
      <Actions :export-to-excel="exportToExcel" />
<!--      <va-chart :data="data"-->
<!--        :options="options"-->
<!--        :style="{height: '500px'}"-->
<!--        type="vertical-bar" />-->
      <Bar
        :chart-id="'bar-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :css-classes="'bar export'"
        :height="500"
        :chart-options="chartOptions"
      />
    </vac-card>
  </div>
</template>

<script>

  import VaChart from "../statistics-template/charts/va-charts/VaChart";
  import Actions from "../actions/Actions";
  import HelperExcel from "../../services/Helpers/HelperExcel";
  import {Bar} from 'vue-chartjs/legacy'

  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

  export default {
    name: "InsightsStatuses",
    components: {
      Actions,
      Bar
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        options: {
          scales: {
            xAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
              type: 'linear',
            }],
          },
        },
      };
    },
    computed: {
      chartOptions() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
              gridLines: {
                display: false,
              },
            },
            y: {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
              type: 'linear',
            },
          },
          indexAxis: 'x',
          plugins: {
            tooltip: {
              mode: 'index'
            },
            legend: {
              position: 'bottom',
              labels: {
                padding: 20,
                usePointStyle: true,
                pointStyle: 'circle'
              }
            },
          }
        };
      },
    },
    methods: {
      exportToExcel() {
        const customData = [];
        const customLabels = ["Retailer", "Unknown", "Empty", "Broken"];
        const { labels, datasets } = this.data;

        datasets.forEach(chart => {
          const { label, data } = chart;
          data.forEach((info, index) => {
            const retailer = labels[index];
            const findIndex = customData.findIndex(found => found["Retailer"] === retailer);
            if (findIndex !== -1) {
              customData[findIndex][label] = info;
            } else {
              const obj = {
                "Retailer": retailer,
                "Unknown": 0,
                "Empty": 0,
                "Broken": 0,
              };

              obj[label] = info || 0;

              customData.push(obj);
            }
          });
        });

        HelperExcel.exportToExcel({}, "Insights Retailers Status", customLabels, customData);
      },
    },
  };
</script>

<style lang="scss" scoped>
.bar {
  height: 500px;
}
</style>
